@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fade-bottom {
    @apply bg-gradient-to-t from-white to-transparent;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap");
:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}
@font-face {
  font-family: "Geograph";
  font-weight: 400;
  font-style: normal;
  src: url("/font/geograph/test-geograph-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Geograph";
  font-weight: 500; /* Assuming medium is 500 weight */
  font-style: normal;
  src: url("/font/geograph/test-geograph-medium.woff2") format("woff2");
}

@font-face {
  font-family: "Geograph";
  font-weight: 700; /* Assuming bold is 700 weight */
  font-style: normal;
  src: url("/font/geograph/test-geograph-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Haas";
  font-weight: 800;
  font-style: normal;
  src: url("/font/haas/NeueHaasDisplayBold.ttf") format("truetype");
}
@font-face {
  font-family: "Haas";
  font-weight: 500;
  font-style: normal;
  src: url("/font/haas/NeueHaasDisplayMediu.ttf") format("truetype");
}
@font-face {
  font-family: "Haas";
  font-weight: 300;
  font-style: normal;
  src: url("/font/haas/NeueHaasDisplayLight.ttf") format("truetype");
}
@font-face {
  font-family: "Haas";
  font-weight: 400;
  font-style: normal;
  src: url("/font/haas/NeueHaasDisplayRoman.ttf") format("truetype");
}
@layer base {
  :root {
    --color-primary: #dcff50;
    --color-secondary: #7f00e3d6;
    --color-heading: #f5f5f7;
    --color-text: rgba(255, 255, 255, 0.6);
    --color-background: #090909;
    --black-1: #3d3b3b;
    font-size: 62.5%;
  }
}

body {
  color: var(--black-1);
  font-size: 12px;
  font-family: InterVariable, sans-serif;
}
.inner-container {
  position: relative;
  padding: 22px;
  margin-top: 20px;
}

.inner-container:first-child {
  max-width: 650px;
}

.container-heading {
  font-size: 24px;
  text-align: start;
}
.highcharts-credits {
  display: none !important;
}

.fading-border {
  width: 100%;
  position: relative;
  background-color: #fff; /* or any background color you prefer */
}

.fading-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px; /* Adjust this for border thickness */
  background: linear-gradient(
    to right,
    transparent,
    transparent 5%,
    #dcdada 50%,
    transparent 95%,
    transparent 100%
  );
}
.add-cell:hover .fading-border::after {
  background: linear-gradient(
    to right,
    transparent,
    transparent 5%,
    rgb(82 82 82 / 1) 50%,
    transparent 95%,
    transparent 100%
  );
}

.square-pattern {
  background-image: linear-gradient(
      to right,
      rgba(8, 8, 8, 0.9) 0%,
      rgba(8, 8, 8, 0.6) 20%,
      rgba(8, 8, 8, 0.2) 50%,
      rgba(8, 8, 8, 0.96) 70%
    ),
    linear-gradient(
      to bottom,
      rgba(8, 8, 8, 0.7) 0%,
      transparent 20%,
      rgba(8, 8, 8, 0.96) 100%
    ),
    linear-gradient(
      transparent calc(50% - 0.5px),
      #fff 0.5px 50%,
      transparent calc(50% + 0.5px) 100%
    ),
    linear-gradient(
      to right,
      transparent calc(50% - 0.5px),
      #fff 0.5px 50%,
      transparent calc(50% + 0.5px) 100%
    );
  background-size: 100% 100%, 100% 100%, 55px 55px, 55px 55px;
  background-position: 28px 28px;
}

.highlight-effect {
  box-shadow: 0 0 10px rgba(88, 193, 254, 0.7), 0 0 20px rgba(78, 134, 255, 0.5); /* Highlight effect */
}